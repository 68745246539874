import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import LanguageProvider from "./context/LanguageContext";
import PosProvider from "./context/PosContext";
import ProjectsProvider from "./context/ProjectsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LanguageProvider>
    <PosProvider>
      <ProjectsProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ProjectsProvider>
    </PosProvider>
  </LanguageProvider>
);
