import React, { useContext } from "react";

import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

import { ImLinkedin } from "react-icons/im";

import CCImg from "../img/about/cc06.png";
import FMImg from "../img/about/fm05.png";

function About() {
  const { text } = useContext(LanguageContext);
  const { aboutPos } = useContext(PosContext);

  const isMobile = window.innerWidth < 768;

  return (
    <div className="aboutPageContainer blurBgBlackEffect4">
      <div
        ref={aboutPos}
        className="aboutTitle fontEthnocentric font4 greenText"
      >
        {text.aboutTitle}
      </div>
      <div className="aboutDescr">{text.aboutDescrTop}</div>
      {/* <div className="aboutBoxes"> */}
      <div className="aboutContainer">
        <img src={CCImg} alt="" className="aboutImg" />
        <div className="flexCol aboutBox aboutBoxCC ">
          <img src={CCImg} alt="" className="aboutImgSmall" />
          <div className="aboutBoxTitle ccTitle font8 fontAudiowide">
            {!isMobile && "Cédric Colonna "}
            <a
              href="https://www.linkedin.com/in/c%C3%A9dric-colonna-9b8393ab/"
              target="_blank"
              className="textColorWhite"
            >
              <ImLinkedin size={22} />
            </a>
            {isMobile && "Cédric Colonna"}
          </div>
          <div className="aboutBoxDescr">
            <div className="aboutQuote">‟</div>
            <div className="aboutBoxText">
              <div>{text.CCDescr1}</div>
              <div style={{ fontStyle: "italic" }}>{text.CCDescr2}</div>
            </div>
            <div className="aboutQuote">”</div>
          </div>
        </div>
      </div>
      <div className="aboutContainer">
        <div className="flexCol aboutBox aboutBoxFM">
          <img src={FMImg} alt="" className="aboutImgSmall" />
          <div className="aboutBoxTitle fmTitle font8 fontAudiowide">
            <a
              href="https://www.linkedin.com/in/fran%C3%A7ois-marconcini-b6107910b/"
              target="_blank"
              className="textColorWhite"
            >
              <ImLinkedin size={22} />
            </a>
            François Marconcini
          </div>
          {/* <div className="text-center px-4">
              <a
                href="https://www.linkedin.com/in/fran%C3%A7ois-marconcini-b6107910b/"
                target="_blank"
                className="textColorWhite absolute mt-[0.55rem] -ml-8"
              >
                <ImLinkedin size={22} />
              </a>
              François Marconcini
            </div> */}
          <div className="aboutBoxDescr">
            <div className="aboutQuote">‟</div>
            <div className="aboutBoxText">
              <div>{text.FMDescr1}</div>
              <div style={{ fontStyle: "italic" }}>{text.FMDescr2}</div>
            </div>
            <div className="aboutQuote">”</div>
          </div>
        </div>
        <img src={FMImg} alt="" className="aboutImg" />
      </div>
      {/* </div> */}
    </div>
  );
}

export default About;
