import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

function Contact() {
  const { text } = useContext(LanguageContext);
  const { contactPos } = useContext(PosContext);

  return (
    <div ref={contactPos} className=" flexCol contactContainer">
      <div className="contactBox">
        <div className="titleContact fontEthnocentric font4 greenText">
          {text.contactTitle}
        </div>
        <form action="https://formsubmit.co/contact@powcoma.com" method="POST">
          <div className="flexCol contactForm">
            {/* <div className="upContactForm"></div> */}
            <div className="nameAndTextContactInputs">
              <input
                type="text"
                name="nom"
                placeholder={text.namePlaceholder}
                className="contactName inputContact fontAudiowide"
              ></input>
              <input
                type="email"
                name="email"
                id="userEmail"
                placeholder={text.mailPlaceholder}
                className="contactEmail inputContact fontAudiowide"
              ></input>
            </div>
            <textarea
              type="text"
              name="message"
              placeholder={text.messagePlaceholder}
              className="contactMessage inputContact fontAudiowide"
            ></textarea>
            <input type="hidden" name="_captcha" value="false"></input>
          </div>
          <button className="sendContactBtn fontOrbitron">
            {text.sendButtonText}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
