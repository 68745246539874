import React, { useContext, useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";

import { ProjectsContext } from "../context/ProjectsContext";
import ChevronUpProjects from "../components/ChevronsProjects";
import { PosContext } from "../context/PosContext";

function Portfolio() {
  const { text } = useContext(LanguageContext);
  const { portfolioPos } = useContext(PosContext);
  const {
    currentProject,
    projects,
    findNextProjectIndex,
    findPreviousProjectIndex,
    previous,
    next,
    setSpecificProject,
    setIsTouched,
  } = useContext(ProjectsContext);

  const isResponsive = window.innerWidth < 800;

  function getProjects() {
    return (
      <>
        {projects?.map((x, i) => {
          return (
            <div
              id={`project${i + 1}`}
              onClick={() => {
                if (i + 1 === findPreviousProjectIndex()) {
                  console.log("on entre dans le cas 1");
                  previous();
                } else if (i + 1 === findNextProjectIndex()) {
                  console.log("on entre dans le cas 2");
                  next();
                } else if (currentProject !== i + 1) {
                  console.log("on entre dans le cas 3");
                  setSpecificProject(i + 1);
                }
              }}
              className="project blurBgBlackEffect3 boxShadowBottomEdge"
            >
              <div className="imagesProject">
                <div className="">
                  <img src={x.photo1} alt="" className="imageProject" />
                </div>
                {x.photo2 && (
                  <div>
                    <img src={x.photo2} alt="" className="imageProject" />
                  </div>
                )}
              </div>
              <div className="contentProject">
                <div className="titleProject fontAudiowide font7">
                  {x.title}
                </div>
                <div className="descrProject font10">{x.descr}</div>
                <div className="logosProject">
                  <div>
                    <img src={x.logo1} alt="" className="logoProject" />
                  </div>
                  {x.logo2 && (
                    <div>
                      <img src={x.logo2} alt="" className="logoProject" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function toggleNext() {
    setIsTouched(true);
    next();
  }

  function togglePrevious() {
    setIsTouched(true);
    previous();
  }

  function toggleSpecific(id) {
    setIsTouched(true);
    setSpecificProject(id);
  }

  function toggleSelf() {
    setIsTouched(true);
  }

  return (
    <>
      {/* <div ref={ref} id="projects" className="h-[100vh] flex items-center">
        <div className="flex flex-col w-full items-center h2 powerElem">
        Portfolio en construction
        </div>
      </div> */}
      <div ref={portfolioPos} id="projects" className="portfolioContainer">
        <div className="portfolioTitle fontEthnocentric font4 greenText">
          {text.portfolioTitle}
        </div>
        <div className="portfolioSlider">
          {projects?.map((x, i) => {
            return (
              <div
                id={`project${i + 1}`}
                onClick={() => {
                  if (i + 1 === findPreviousProjectIndex()) {
                    togglePrevious();
                  } else if (i + 1 === findNextProjectIndex()) {
                    toggleNext();
                  } else if (currentProject !== i + 1) {
                    toggleSpecific(i + 1);
                  } else {
                    toggleSelf();
                  }
                }}
                // className="project projectDuration1 absolute flex h-[65vh] w-[60vw] mx-auto items-center bg-slate-600"
                className="project blurBgBlackEffect3 boxShadowBottomEdge"
              >
                {x.photo1 && (
                  <div className="hidden xl:flex imagesProject">
                    <div className="">
                      <img
                        src={x.photo1}
                        alt=""
                        className="imageProject"
                        // className={`${x.photo2 ? "h-44" : "h-80"}`}
                      />
                    </div>
                    {x.photo2 && (
                      <div className="">
                        <img src={x.photo2} alt="" className="imageProject" />
                        {/* <img src={x.photo2} alt="" className="h-44" /> */}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`contentProject ${
                    x.photo1 ? "justify-around" : "justify-evenly"
                  }`}
                >
                  {x.logo1 && (
                    <div className="flex xl:hidden logosPSmall">
                      <div className="">
                        <img
                          src={x.logo1}
                          alt=""
                          // className="logoProjectSmall"
                          className={`${
                            x.logo2
                              ? "logoProjectSmall"
                              : "logoProjectSmallAlone"
                          }`}
                        />
                      </div>
                      {x.logo2 && (
                        <div className="">
                          <img
                            src={x.logo2}
                            alt=""
                            className="logoProjectSmall"
                          />
                          {/* className="h-20" /> */}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="titleProject fontAudiowide font11">
                    {x.title}
                  </div>
                  <div
                    className={`descrProject font10 ${
                      x.logo1 !== "" ? "max-h-[60%]" : "max-h-[85%]"
                    } overflow-y-auto scrollbar scrollbar-w-1 scrollbar-thumb-gray-900 scrollbar-track-slate-300 scrollbar-thumb-rounded-full scrollbar-track-rounded-full`}
                  >
                    {x.descr}
                  </div>
                  {x.logo1 && (
                    <div className="hidden xl:flex logosProject">
                      <div className="">
                        <img
                          src={x.logo1}
                          alt=""
                          // className="logoProject"
                          className={`${x.logo2 ? "h-28" : "h-40"}`}
                        />
                      </div>
                      {x.logo2 && (
                        <div className="">
                          <img
                            src={x.logo2}
                            alt=""
                            // className="logoProject" />
                            className="h-28"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`flex xl:hidden imagesProjectSmall ${
                      x.photo1 ? "" : ""
                    }`}
                  >
                    {x.photo1 && (
                      <div
                        className={`${x.logo1 || isResponsive ? "" : "pt-2"}`}
                      >
                        <img
                          src={x.photo1}
                          alt=""
                          // className="imageProject"
                          className={`${
                            x.photo2
                              ? "imageProjectSmall"
                              : "imageProjectSmallAlone"
                          }`}
                        />
                      </div>
                    )}
                    {x.photo2 && (
                      <div className="">
                        {/* <img src={x.photo2} alt="" className="imageProject" /> */}
                        <img
                          src={x.photo2}
                          alt=""
                          className="imageProjectSmall"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ChevronUpProjects />
    </>
  );

  // return (
  //   <>
  //     <div ref={portfolioPos} className="portfolioContainer">
  //       <div className="portfolioTitle fontEthnocentric font4 greenText">
  //         {text.portfolioTitle}
  //       </div>
  //       <div className="portfolioSlider">
  //         {projects?.map((x, i) => {
  //           return (
  //             <div
  //               id={`project${i + 1}`}
  //               onClick={() => {
  //                 if (i + 1 === findPreviousProjectIndex()) {
  //                   console.log("on entre dans le cas 1");
  //                   previous();
  //                 } else if (i + 1 === findNextProjectIndex()) {
  //                   console.log("on entre dans le cas 2");
  //                   next();
  //                 } else if (currentProject !== i + 1) {
  //                   console.log("on entre dans le cas 3");
  //                   setSpecificProject(i + 1);
  //                 }
  //               }}
  //               className="project blurBgBlackEffect3 boxShadowBottomEdge"
  //             >
  //               <div className="imagesProject">
  //                 <div className="">
  //                   <img src={x.photo1} alt="" className="imageProject" />
  //                 </div>
  //                 {x.photo2 && (
  //                   <div>
  //                     <img src={x.photo2} alt="" className="imageProject" />
  //                   </div>
  //                 )}
  //               </div>
  //               <div className="contentProject">
  //                 <div className="titleProject fontAudiowide font7">
  //                   {x.title}
  //                 </div>
  //                 <div className="descrProject font10">{x.descr}</div>
  //                 <div className="logosProject">
  //                   <div>
  //                     <img src={x.logo1} alt="" className="logoProject" />
  //                   </div>
  //                   {x.logo2 && (
  //                     <div>
  //                       <img src={x.logo2} alt="" className="logoProject" />
  //                     </div>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //     <ChevronUpProjects />
  //   </>
  // );
}

export default Portfolio;
