import React, { useState, useEffect, useRef, createContext } from "react";

export const PosContext = createContext();

const PosProvider = ({ children }) => {
  const [introPos, setIntroPos] = useState(useRef());
  const [servicesPos, setServicesPos] = useState(useRef());
  const [portfolioPos, setPortfolioPos] = useState(useRef());
  const [aboutPos, setAboutPos] = useState(useRef());
  const [contactPos, setContactPos] = useState(useRef());

  function goTo(ref) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  function goToIntro() {
    goTo(introPos);
  }

  function goToServices() {
    goTo(servicesPos);
  }

  function goToPortfolio() {
    goTo(portfolioPos);
  }

  function goToAbout() {
    goTo(aboutPos);
  }

  function goToContact() {
    goTo(contactPos);
  }

  return (
    <PosContext.Provider
      value={{
        introPos,
        servicesPos,
        portfolioPos,
        aboutPos,
        contactPos,
        goToIntro,
        goToServices,
        goToPortfolio,
        goToAbout,
        goToContact,
      }}
    >
      {children}
    </PosContext.Provider>
  );
};

export default PosProvider;
