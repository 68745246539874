import React, { useContext, useEffect } from "react";
import {
  BiChevronLeft,
  BiChevronsLeft,
  BiChevronRight,
  BiChevronsRight,
  BiSolidCircle,
} from "react-icons/bi";
import { ProjectsContext } from "../context/ProjectsContext";

const ChevronUpProjects = () => {
  const {
    projectsLength,
    currentProject,
    findNextProjectIndex,
    findPreviousProjectIndex,
    next,
    previous,
    setFirstProject,
    setLastProject,
    setSpecificProjectSU,
    setIsTouched,
  } = useContext(ProjectsContext);

  function goToProject(id) {
    setIsTouched(true);
    if (projectsLength < 6) {
      if (
        id === findPreviousProjectIndex() ||
        id + projectsLength === findPreviousProjectIndex()
      ) {
        previous();
      } else if (
        id === findNextProjectIndex() ||
        id + projectsLength === findNextProjectIndex()
      ) {
        next();
      } else {
        if (id !== currentProject && id + projectsLength !== currentProject) {
          console.log("meeeh");
          console.log(id);
          console.log(projectsLength);
          console.log(currentProject);
          setSpecificProjectSU(id);
        }
      }
    } else {
      console.log(projectsLength);
      if (id === findPreviousProjectIndex()) {
        previous();
      } else if (id === findNextProjectIndex()) {
        next();
      } else {
        setSpecificProjectSU(id);
      }
    }
  }

  function moveToFirst() {
    setIsTouched(true);
    if (currentProject === 1) {
      return;
    }
    if (projectsLength < 6) {
      if (currentProject === projectsLength + 1) {
        return;
      }
      if (projectsLength - currentProject < 0) {
        setSpecificProjectSU(projectsLength + 1);
      } else {
        setFirstProject();
      }
    } else {
      setFirstProject();
    }
  }

  function moveToLast() {
    setIsTouched(true);
    if (currentProject === projectsLength) {
      return;
    }
    if (projectsLength < 6) {
      if (currentProject === projectsLength * 2) {
        return;
      }
      if (projectsLength - currentProject < 0) {
        setSpecificProjectSU(projectsLength * 2);
      } else {
        setLastProject();
      }
    } else {
      setLastProject();
    }
  }

  function togglePrevious() {
    setIsTouched(true);
    previous();
  }

  function toggleNext() {
    setIsTouched(true);
    next();
  }

  return (
    <>
      <div className="chevronsPContainer">
        <div className="chevronsPSection blurBgBlackEffect3 boxShadowBottomEdge2">
          <BiChevronsLeft
            onClick={moveToFirst}
            size="1.9em"
            className="chevronsPLeft"
            style={{ cursor: "pointer" }}
          />
          <BiChevronLeft
            onClick={togglePrevious}
            size="1.9em"
            style={{ cursor: "pointer" }}
          />
          <div className="chevronsP">
            {Array.from({ length: projectsLength }, (v, k) => k).map(
              (el, index) => {
                return (
                  <BiSolidCircle
                    onClick={() => {
                      goToProject(index + 1);
                    }}
                    size="0.77em"
                    style={{
                      cursor: "pointer",
                      opacity:
                        projectsLength < 6
                          ? currentProject === index + 1 ||
                            currentProject - projectsLength === index + 1
                            ? "1"
                            : "0.4"
                          : currentProject === index + 1
                          ? "1"
                          : "0.4",
                    }}
                  />
                );
              }
            )}
          </div>
          <BiChevronRight
            onClick={toggleNext}
            size="1.9em"
            style={{ cursor: "pointer" }}
          />
          <BiChevronsRight
            onClick={moveToLast}
            size="1.9em"
            className="chevronsPRight"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </>
  );
};

export default ChevronUpProjects;
