import React, { useContext, useState } from "react";

import Logo from "../img/logo/logo.png";

import { IoMdClose } from "react-icons/io";
import { BiMenu } from "react-icons/bi";

import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

import LanguageSelector from "./LanguageSelector";

function Header() {
  const { text } = useContext(LanguageContext);
  const { goToIntro, goToServices, goToPortfolio, goToAbout, goToContact } =
    useContext(PosContext);

  const [isOpenedMenu, setIsOpenedMenu] = useState(false);

  function toggleMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  function toggleServices() {
    toggleMenu();
    goToServices();
  }

  function togglePortfolio() {
    toggleMenu();
    goToPortfolio();
  }

  function toggleAbout() {
    toggleMenu();
    goToAbout();
  }

  function toggleContact() {
    toggleMenu();
    goToContact();
  }

  return (
    <>
      <header className="header blurEffectBg">
        <div onClick={goToIntro} className="logoHeaderContainer">
          <img src={Logo} alt="Logo" className="headerLogo" />
          <div className="textHeaderLogo greenText font1">Powcoma</div>
        </div>
        <div className="navHeaderContainer">
          <nav className="headerNav font2 greenText">
            <div onClick={goToServices} className="pointer">
              {text.servicesLink}
            </div>
            <div onClick={goToPortfolio} className="pointer">
              {text.portfolioLink}
            </div>
            <div onClick={goToAbout} className="pointer">
              {text.aboutLink}
            </div>
            <div onClick={goToContact} className="pointer">
              {text.contactLink}
            </div>
          </nav>
          <div className="pointer">
            <LanguageSelector />
          </div>
        </div>
        {!isOpenedMenu && (
          <div onClick={toggleMenu} className="headerMenuIconOpen pointer">
            <BiMenu />
          </div>
        )}
        {isOpenedMenu && (
          <div className="headerMenu">
            <div className="headerMenuOpen headerMenuLinksBg">
              <div onClick={toggleMenu} className="headerMenuIconClose">
                <IoMdClose />
              </div>
              <div className="headerMenuLinks font9 fontOrbitron">
                <div onClick={toggleServices} className="pointer">
                  {text.servicesLink}
                </div>
                <div>{text.portfolioLink}</div>
                <div onClick={toggleAbout} className="pointer">
                  {text.aboutLink}
                </div>
                <div onClick={toggleContact} className="pointer">
                  {text.contactLink}
                </div>
                <div className="pointer">
                  <LanguageSelector />
                </div>
              </div>
              {/* <LanguageSelector /> */}
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default Header;
