import React, { useEffect } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function ServiceContentLeft({ title, content, content2, img, icon }) {
  return (
    <div className="serviceContentLeftContainer">
      {/* <div className="imageServiceContentLeft"></div> */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: "rgb(255, 255, 255)",
          border: "1px solid rgb(0, 0, 0)",
          color: "#000",
          // fontSize: "1rem",
          // marginTop: "4rem",
          // marginBottom: "4rem",
        }}
        contentArrowStyle={{
          borderRight: "7px solid rgb(0, 0, 0)",
          marginTop: "6.5rem",
        }}
        iconStyle={{
          background: "rgb(7, 145, 51)",
          color: "#fff",
          marginTop: "6.5rem",
        }}
        icon={icon}
      >
        <div className="titleBoxService fontAudiowide font7">{title}</div>
        <div className="textBoxService">
          {content}
          {content2 && <br />}
          {content2 && <br />}
          {content2}
        </div>
        <div className="imageServiceContentSmall">
          <img src={img} alt="" className="imageServices" />
        </div>
      </VerticalTimelineElement>
      {/* <div>aaaaaaaaaaa</div> */}
      <div className="imageServiceContentLeft">
        <img src={img} alt="" className="imageServices" />
      </div>
    </div>
  );
}

export default ServiceContentLeft;
