// import testImg1 from "../img/easy-electronics-project.jpg";
// import testImg2 from "../img/IA_elec.png";
// import testImg3 from "../img/istockphoto.jpg";

/* ADHA */
import logo1ADHA from "../img/portfolio/ADHA/logo1.png";
import logo2ADHA from "../img/portfolio/ADHA/logo5.png";
import photo1ADHA from "../img/portfolio/ADHA/photo1.png";

/* Ariane */
import logo1Ariane from "../img/portfolio/Ariane/logo31.png";

/* ISD */
import logo1ISD from "../img/portfolio/ISD/logo1.png";
import logo2ISD from "../img/portfolio/ISD/logo4.png";
import photo1ISD from "../img/portfolio/ISD/photo1.png";
import photo2ISD from "../img/portfolio/ISD/photo431.png";

export var projectsData = [
  {
    titleEn: "ADHA",
    titleFr: "ADHA",
    descrEn:
      "Creation of a 28V input converter generating three types of isolated outputs for a total power of 200W at high current. The converter will be designed to withstand space environments (temperatures, radiation, etc.) according to ECSS-20-20 standards while incorporating hot redundancy on the output buses. Several protections are implemented: Overcurrent protection (LCL), Undervoltage protection (UVD), Overvoltage protection (OVP), Filter complying with ECSS constraints, Double insulation, Telemetry. Expected peak efficiencies are greater than 92% with an innovative conversion structure and embedded technologies enabling a higher operating frequency in a reduce volume..",
    descrFr:
      "Création d'un convertisseur 28V d'entrée générant trois types de sortie isolé pour une puissance totale de 200W en fort courant. Le convertisseur sera designé pour tenir les environnements spatiales (températures, radiations..) suivant les normes ECSS-20-20, tout en embarquant une redondance chaude . Plusieurs protections sont implémentées : - Protection en sur-courant (LCL), - Protection en sous tension (UVD), - Protection en surtensions (OVP), - Filtre respectant les contraintes ECSS, - Double isolation, - Télémétries. Les rendements pics attendus sont supérieurs à 92% avec une structure de conversion innovantes et des technologies embarquées permettant une plus haute fréquence de fonctionnement dans un volume réduit.",
    logo1: logo1ADHA,
    logo2: logo2ADHA,
    photo1: photo1ADHA,
    photo2: "",
  },
  {
    titleEn: "Ariane",
    titleFr: "Ariane",
    descrEn:
      "Within ArianeGroup's facilities, Powcoma oversees the development of a power distribution equipment initially co-designed by one of Powcoma's members. This involves the realization and monitoring of equipment qualification, the implementation and support of various tests, as well as design improvements and associated project management.",
    descrFr:
      "Au sein des locaux d'ArianeGroup, Powcoma assure le suivi de développement d'un équipement distributeur de puissance co-designé par un des membres de Powcoma. Ceci passe par la réalisation et le suivi de la qualification de l'équipement, la mise en place et le support aux différents tests ou encore les améliorations de designs et la gestion de projet associé.",
    logo1: logo1Ariane,
    logo2: "",
    photo1: "",
    photo2: "",
  },
  {
    titleEn: "ISD",
    titleFr: "ISD",
    descrEn:
      "In collaboration with Powerlogy, we developed a high-current low-voltage spatial converter (70W). The converter utilizes a Sigma architecture and can deliver 50A at 1V from a 100V primary bus. Protections have been implemented (UVD, OVP, OCP). The magnetics are designed using planar technology with custom magnetic cores, and the power transistors are based on GaN technology. An in-depth study of the magnetics has been conducted. The system complies with design standards and derating requirements specified in ESA ECSS-20-20, including double insulation and input filter levels.",
    descrFr:
      "En collaboration avec Powerlogy, développement d'un convertisseur spatial basse tension fort courant (70W). Le convertisseur utilise une architecture Sigma et permet de fournir 50A sous 1V à partir d'une barre primaire 100V. Des protections sont implémentées (UVD, OVP, OCP). Les magnétiques sont designés en technologie planar à partir de noyaux magnétiques custom et les transistor de puissance sont en technologie GaN. Une étude approfondie des magnétiques à été menée. Le système respecte les standards de design et de derating ESA ECSS-20-20 comme sur la double isolation ou au niveau des filtres d'entrée.",
    logo1: logo1ISD,
    logo2: logo2ISD,
    photo1: photo1ISD,
    photo2: photo2ISD,
  },
  //   {
  //     titleEn: "ADHA2",
  //     titleFr: "ADHA2",
  //     descrEn:
  //       "Creation of a 28V input converter generating three types of isolated outputs for a total power of 200W at high current. The converter will be designed to withstand space environments (temperatures, radiation, etc.) according to ECSS-20-20 standards while incorporating hot redundancy on the output buses. Several protections are implemented: Overcurrent protection (LCL), Undervoltage protection (UVD), Overvoltage protection (OVP), Filter complying with ECSS constraints, Double insulation, Telemetry. Expected peak efficiencies are greater than 92% with an innovative conversion structure and embedded technologies enabling a higher operating frequency in a reduce volume..",
  //     descrFr:
  //       "Création d'un convertisseur 28V d'entrée générant trois types de sortie isolé pour une puissance totale de 200W en fort courant. Le convertisseur sera designé pour tenir les environnements spatiales (températures, radiations..) suivant les normes ECSS-20-20, tout en embarquant une redondance chaude . Plusieurs protections sont implémentées : - Protection en sur-courant (LCL), - Protection en sous tension (UVD), - Protection en surtensions (OVP), - Filtre respectant les contraintes ECSS, - Double isolation, - Télémétries. Les rendements pics attendus sont supérieurs à 92% avec une structure de conversion innovantes et des technologies embarquées permettant une plus haute fréquence de fonctionnement dans un volume réduit.",
  //     logo1: logo1ADHA,
  //     logo2: logo2ADHA,
  //     photo1: photo1ADHA,
  //     photo2: "",
  //   },
  // {
  //   titleEn: "Ariane",
  //   titleFr: "Ariane",
  //   descrEn:
  //     "Within ArianeGroup's facilities, Powcoma oversees the development of a power distribution equipment initially co-designed by one of Powcoma's members. This involves the realization and monitoring of equipment qualification, the implementation and support of various tests, as well as design improvements and associated project management.",
  //   descrFr:
  //     "Au sein des locaux d'ArianeGroup, Powcoma assure le suivi de développement d'un équipement distributeur de puissance co-designé par un des membres de Powcoma. Ceci passe par la réalisation et le suivi de la qualification de l'équipement, la mise en place et le support aux différents tests ou encore les améliorations de designs et la gestion de projet associé.",
  //   logo1: "",
  //   logo2: "",
  //   photo1: logo1Ariane,
  //   photo2: "",
  // },
  // {
  //   titleEn: "ISD",
  //   titleFr: "ISD",
  //   descrEn:
  //     "In collaboration with Powerlogy, we developed a high-current low-voltage spatial converter (70W). The converter utilizes a Sigma architecture and can deliver 50A at 1V from a 100V primary bus. Protections have been implemented (UVD, OVP, OCP). The magnetics are designed using planar technology with custom magnetic cores, and the power transistors are based on GaN technology. An in-depth study of the magnetics has been conducted. The system complies with design standards and derating requirements specified in ESA ECSS-20-20, including double insulation and input filter levels.",
  //   descrFr:
  //     "En collaboration avec Powerlogy, développement d'un convertisseur spatial basse tension fort courant (70W). Le convertisseur utilise une architecture Sigma et permet de fournir 50A sous 1V à partir d'une barre primaire 100V. Des protections sont implémentées (UVD, OVP, OCP). Les magnétiques sont designés en technologie planar à partir de noyaux magnétiques custom et les transistor de puissance sont en technologie GaN. Une étude approfondie des magnétiques à été menée. Le système respecte les standards de design et de derating ESA ECSS-20-20 comme sur la double isolation ou au niveau des filtres d'entrée.",
  //   logo1: logo1ISD,
  //   logo2: logo2ISD,
  //   photo1: photo1ISD,
  //   photo2: photo2ISD,
  // },
  // {
  //   titleEn: "Project 4",
  //   titleFr: "Projet 4",
  //   descrEn: "Descr 4",
  //   descrFr: "Descri 4",
  //   logo1: testImg1,
  //   logo2: testImg1,
  //   photo1: testImg1,
  //   photo2: testImg1,
  // },
  // {
  //   titleEn: "Project 5",
  //   titleFr: "Projet 5",
  //   descrEn: "Descr 5",
  //   descrFr: "Descri 5",
  //   logo1: testImg2,
  //   logo2: testImg2,
  //   photo1: testImg2,
  //   photo2: testImg2,
  // },
  // {
  //   titleEn: "Project 6",
  //   titleFr: "Projet 6",
  //   descrEn: "Descr 6",
  //   descrFr: "Descri 6",
  //   logo1: testImg3,
  //   logo2: testImg3,
  //   photo1: testImg3,
  //   photo2: testImg3,
  // },
];
