import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Website from "./pages/Website";
import Header from "./components/Header";

const App = () => {
  return (
    // <Router>
    //   </Router>
    <>
      <Header />
      <Website />
      {/* <Header />
      <Home /> */}
    </>
  );
};

export default App;
