import React, { useContext } from "react";

import { LanguageContext } from "../context/LanguageContext";

import FormationImg from "../img/services/formation4.png";
import SupportImg from "../img/services/support4.png";

function OthersActivities() {
  const { text } = useContext(LanguageContext);

  return (
    <div className="OABg">
      <div className="OAContainer blurBgBlackEffect">
        <div className="OASection">
          <div className="OATitle fontEthnocentric font4 greenText">
            {text.otherActivitiesSubTitle}
          </div>
          <div className="boxesOA">
            <div className="flexCol boxOA blurEffectBg boxShadowG">
              <div className="titleBoxOA fontAudiowide font7">
                {text.formationSubTitle}
              </div>
              <div className="">
                <img
                  src={FormationImg}
                  // className="h-72 xll:h-[22.5rem] xxxl:h-96"
                  className="OAIgm"
                />
              </div>
              <div className="textBoxOA">{text.formationDescr}</div>
            </div>
            <div className="flexCol boxOA blurEffectBg boxShadowD">
              <div className="titleBoxOA fontAudiowide font7">
                {text.supportSubTitle}
              </div>
              <div className="">
                <img
                  src={SupportImg}
                  // className="h-72 xll:h-[22.5rem] xxxl:h-96"
                  className="OAIgm"
                />
              </div>
              <div className="textBoxOA">{text.supportDescr}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OthersActivities;
