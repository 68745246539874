import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";

import { projectsData } from "../data/projects";
import { LanguageContext } from "./LanguageContext";

export const ProjectsContext = createContext();

const nextProjectDelay = 5;

const ProjectsProvider = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const [lastTriggeredTime, setLastTriggeredTime] = useState(Date.now());
  const [currentProject, setCurrentProject] = useState(1);
  const [targetedProject, setTargetedProject] = useState(1);
  const [projects, setProjects] = useState();
  const [projectsLength, setProjectsLength] = useState(projectsData.length);
  const [isTouched, setIsTouched] = useState(false);
  const [autoplay, setAutoplay] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const [title, setTitle] = useState("");
  // const [descr, setDescr] = useState("");

  useEffect(() => {
    constructProjects();
    setProjectsLength(projectsData.length);

    return () => {};
  }, []);

  useEffect(() => {
    if (projects) {
      init();
    }

    return () => {};
  }, [projects]);

  useEffect(() => {
    let timeout1 = setTimeout(() => {
      if (currentProject - targetedProject > 0) {
        decreaseProject();
      } else if (currentProject - targetedProject < 0) {
        increaseProject();
      } else {
        clearSpeedUp();
      }
    }, 100);

    return () => {
      clearTimeout(timeout1);
    };
  }, [currentProject]);

  function putSpeedUp() {
    for (let el of document.getElementsByClassName("project")) {
      el.classList.remove("projectDuration1");
      el.classList.add("projectDuration2");
    }
  }

  function clearSpeedUp() {
    for (let el of document.getElementsByClassName("project")) {
      el.classList.add("projectDuration1");
      el.classList.remove("projectDuration2");
    }
  }

  function init() {
    for (let el of document.getElementsByClassName("project")) {
      el?.classList.add("hidden");
    }

    document
      .getElementById("project" + findDubbleNextProjectIndex())
      ?.classList.add("projectIn2");
    document
      .getElementById("project" + findDubbleNextProjectIndex())
      ?.classList.remove("hidden");
    document
      .getElementById("project" + findNextProjectIndex())
      ?.classList.add("projectIn1");
    document
      .getElementById("project" + findNextProjectIndex())
      ?.classList.remove("hidden");
    document
      .getElementById("project" + currentProject)
      ?.classList.add("projectCenter");
    document
      .getElementById("project" + currentProject)
      ?.classList.remove("hidden");
    document
      .getElementById("project" + findPreviousProjectIndex())
      ?.classList.add("projectOut1");
    document
      .getElementById("project" + findPreviousProjectIndex())
      ?.classList.remove("hidden");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      ?.classList.add("projectOut2");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      ?.classList.remove("hidden");
  }

  function findPreviousProjectIndex() {
    if (currentProject === 1) {
      return projects.length;
    } else {
      return currentProject - 1;
    }
  }

  function findDubblePreviousProjectIndex() {
    if (findPreviousProjectIndex() === 1) {
      return projects.length;
    } else {
      return findPreviousProjectIndex() - 1;
    }
  }

  function findTripplePreviousProjectIndex() {
    if (findDubblePreviousProjectIndex() === 1) {
      return projects.length;
    } else {
      return findDubblePreviousProjectIndex() - 1;
    }
  }

  function findNextProjectIndex() {
    if (currentProject === projects.length) {
      return 1;
    } else {
      return currentProject + 1;
    }
  }

  function findDubbleNextProjectIndex() {
    if (findNextProjectIndex() === projects.length) {
      return 1;
    } else {
      return findNextProjectIndex() + 1;
    }
  }

  function findTrippleNextProjectIndex() {
    if (findDubbleNextProjectIndex() === projects.length) {
      return 1;
    } else {
      return findDubbleNextProjectIndex() + 1;
    }
  }

  useEffect(() => {
    let intervalDelay = isFirstLoad ? 1 : nextProjectDelay * 1000;
    setIsFirstLoad(false);
    let autoplayInterval = setTimeout(function () {
      if (projects) {
        next();
      }
      setLastTriggeredTime(Date.now());
    }, intervalDelay);

    setAutoplay(autoplayInterval);
    console.log("on passe dans le useEffect de l'autoplay");
    return () => {};
  }, [lastTriggeredTime]);

  useEffect(() => {
    clearInterval(autoplay);

    return () => {};
  }, [isTouched]);

  useEffect(() => {
    constructProjects();
    // updateText();

    return () => {};
  }, [language]);

  // function updateText() {
  //   if (language === "EN") {
  //     setTitle(projectsData[currentProject - 1].titleEn);
  //     setDescr(projectsData[currentProject - 1].descrEn);
  //   } else {
  //     setTitle(projectsData[currentProject - 1].titleFr);
  //     setDescr(projectsData[currentProject - 1].descrFr);
  //   }
  // }

  function constructProjects() {
    let isLittlePortfolio = projectsData.length < 6;
    setProjects([]);
    let projectsArr = [];

    projectsData.forEach((project) => {
      let projectObj = {};
      projectObj.logo1 = project.logo1;
      projectObj.logo2 = project.logo2;
      projectObj.photo1 = project.photo1;
      projectObj.photo2 = project.photo2;
      projectObj.isUniqueLogo = project.isUniqueLogo;
      projectObj.isUniquePicture = project.isUniquePicture;
      if (language === "EN") {
        projectObj.title = project.titleEn;
        projectObj.descr = project.descrEn;
      } else {
        projectObj.title = project.titleFr;
        projectObj.descr = project.descrFr;
      }
      projectsArr.push(projectObj);
    });

    if (isLittlePortfolio) {
      projectsData.forEach((project) => {
        let projectObj = {};
        projectObj.logo1 = project.logo1;
        projectObj.logo2 = project.logo2;
        projectObj.photo1 = project.photo1;
        projectObj.photo2 = project.photo2;
        projectObj.isUniqueLogo = project.isUniqueLogo;
        projectObj.isUniquePicture = project.isUniquePicture;
        if (language === "EN") {
          projectObj.title = project.titleEn;
          projectObj.descr = project.descrEn;
        } else {
          projectObj.title = project.titleFr;
          projectObj.descr = project.descrFr;
        }
        projectsArr.push(projectObj);
      });
    }

    setProjects(projectsArr);
  }

  function next() {
    setTargetedProject(findNextProjectIndex());
    increaseProject();
  }

  function increaseProject() {
    document
      .getElementById("project" + findTrippleNextProjectIndex())
      .classList.remove("hidden");
    document
      .getElementById("project" + findTrippleNextProjectIndex())
      .classList.add("projectIn2");
    document
      .getElementById("project" + findDubbleNextProjectIndex())
      .classList.remove("projectIn2");
    document
      .getElementById("project" + findDubbleNextProjectIndex())
      .classList.add("projectIn1");
    document
      .getElementById("project" + findNextProjectIndex())
      .classList.remove("projectIn1");
    document
      .getElementById("project" + findNextProjectIndex())
      .classList.add("projectCenter");
    document
      .getElementById("project" + currentProject)
      .classList.remove("projectCenter");
    document
      .getElementById("project" + currentProject)
      .classList.add("projectOut1");
    document
      .getElementById("project" + findPreviousProjectIndex())
      .classList.remove("projectOut1");
    document
      .getElementById("project" + findPreviousProjectIndex())
      .classList.add("projectOut2");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      .classList.remove("projectOut2");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      .classList.add("hidden");
    setCurrentProject(findNextProjectIndex());
  }

  function previous() {
    setTargetedProject(findPreviousProjectIndex());
    decreaseProject();
  }

  function decreaseProject() {
    document
      .getElementById("project" + findTripplePreviousProjectIndex())
      .classList.add("projectOut2");
    document
      .getElementById("project" + findTripplePreviousProjectIndex())
      .classList.remove("hidden");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      .classList.add("projectOut1");
    document
      .getElementById("project" + findDubblePreviousProjectIndex())
      .classList.remove("projectOut2");
    document
      .getElementById("project" + findPreviousProjectIndex())
      .classList.add("projectCenter");
    document
      .getElementById("project" + findPreviousProjectIndex())
      .classList.remove("projectOut1");
    document
      .getElementById("project" + currentProject)
      .classList.add("projectIn1");
    document
      .getElementById("project" + currentProject)
      .classList.remove("projectCenter");
    document
      .getElementById("project" + findNextProjectIndex())
      .classList.add("projectIn2");
    document
      .getElementById("project" + findNextProjectIndex())
      .classList.remove("projectIn1");
    document
      .getElementById("project" + findDubbleNextProjectIndex())
      .classList.add("hidden");
    document
      .getElementById("project" + findDubbleNextProjectIndex())
      .classList.remove("projectIn2");
    setCurrentProject(findPreviousProjectIndex());
  }

  function setFirstProject() {
    setSpecificProjectSU(1);
  }

  function setSpecificProjectSU(projectIndex) {
    putSpeedUp();
    setSpecificProject(projectIndex);
  }

  function setSpecificProject(projectIndex) {
    setTargetedProject(projectIndex);
    if (currentProject > projectIndex) {
      decreaseProject();
    } else {
      increaseProject();
    }
  }

  function setLastProject() {
    setSpecificProjectSU(projectsLength);
  }

  // function nextProject() {
  //   console.log("on passe dans nextPro avec :");
  //   console.log("currentProject");
  //   console.log(currentProject);
  //   console.log("projectsLength");
  //   console.log(projectsLength);
  //   if (currentProject < projectsLength) {
  //     increaseProject();
  //   } else {
  //     setFirstProject();
  //   }
  // }

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        projectsLength,
        currentProject,
        findNextProjectIndex,
        findPreviousProjectIndex,
        next,
        previous,
        setFirstProject,
        setLastProject,
        setSpecificProject,
        setSpecificProjectSU,
        setIsTouched,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsProvider;
